

.testimonial {
  padding: 3% 0%;
   
    
}


.rating {
    margin: auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #040729;
    border-radius: 40px;
    
}

@media  (max-width: 650px) {
    .rating {
        width: 100%;
        border-radius: 0px;
        margin: 0px;

    }

    .rate-container .rate {
      width: 300px;
    }
}

.rate {
    display: block;
    text-align: left;
    width: 320px;
    padding: 1% 3%;
    background: #FFFFFF;
    box-shadow: 3px 4px 10px #f4f5f5a4;
    border-radius: 16px;

}
.rate-container {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    gap: 20px;
    height: 100%;
    margin: 2%;
    /* -ms-overflow-style: none;   Internet Explorer 10+ */
    scrollbar-width: 30%;  /* Firefox */
    margin-bottom: 2%;
}

.rate-container::-webkit-scrollbar {
   
    width: 20px
}

.head {
    padding: 10px 25px;
    gap: 10px;
    width: fit-content;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */
    margin: 20px auto;
    text-align: center;

    /* primary/blue */

    color: #262C71;
    /* accent/hover color */

    background: #F0FAFE;
    border-radius: 16px;
}

.checked {
    color: orange;
      }
  
.rate-heading {
    display: flex;
    flex-direction: row;
    width:inherit;
    
  }

  


  .rate-heading p {
    margin-right: 0%;
    margin-left: auto;
  }
  
  .rate-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .hero-img {
    
    border-radius: 50%;
    background: #515151;
    padding: 10px;
    color: #9b9999;
    text-align: center;
    margin: 5px;
    
  }

  #fullname {
    font-size: 17px;
    font-weight: bold;
    margin: auto;
   
  }

.rating-container {
    display: flex;
    flex-direction: row;
    height: fit-content;
}

@media  (max-width: 750px) {
.rating-container button {
  display: none;
}

}

.rating-container .scroll-button {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin: auto 20px;
  box-shadow: 3px 4px 10px #f4f5f5a4;
}

.rating-container button i {
  margin: auto;
  padding-left: 2px;
}

#one {
  margin-left: '50px';
}

.scroll-bar {
  display: flex;
  flex-direction:row-reverse;
  overflow-x: scroll;
  position: absolute;
  gap: 2px;
  height: auto;
  
  /* -ms-overflow-style: none;   Internet Explorer 10+ */
  scrollbar-width: 20px;  /* Firefox */
  margin: auto;
}

.scroll-bar::-webkit-scrollbar {
   
  width: 20px;
}

.scroll-bar button {
  border-radius: 5px;
  width: 5vw;
  height: 10px;
  margin: 0px;
  cursor:unset;
}


.scroll-bar-container {
  width: 15vw;
  background: #8b8b8b;
  margin: auto;
  margin-bottom: 20px;
  padding: 0px 1px;
  height: 10px;
  border-radius: 5px;
}