.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    gap: 24px;
    padding-top: 20px;
    margin: auto;
    justify-content: center;
    width: 100%;
    color: #e0dcdc;
    opacity: 0.9;
    /* primary/blue */

    background: #262C71;

}

.footer-company li, .footer-services li {
    line-height: 2.6;
}

.contact-add li {
    line-height: 1.6;
}

 .footer a {
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
}

i {
    padding-right: 15px;
}


.footer-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    padding: auto;
    
}


    .footer-services, .footer-company, .footer-contact {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        list-style: none;
        text-align: left;
        max-width: 350px;
        min-width: 250px;
        padding: 20px;
    }
    
   

.footer-contact .icon {
    align-items: flex-start;
}


    .divider {
        
height: 1px;
width: 60%;
position: relative;
background: #FFFFFF;
opacity: 0.1;
margin: auto;



    }


    .copyrights {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 290px;
        gap: 24px;
        padding: 0px;

        /* Whites/plain white */

        color: #FFFFFF;


    }


    .socials {
        display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
gap: 24px;

width: 168px;
height: 24px;



    }

    .socials img {
        width: 24px;
        height: 24px;
    }

    
    @media (max-width: 650px) {
        .socials {
            padding: 2px;
            margin: auto;
         }

         .footer-socials {
            display: flex;
            flex-direction: column;
            justify-content: center;
         }
    }