.contact {
display: flex;
flex-direction: column;
align-items: center;
padding: auto;
gap: 54px;
width: 90%;
text-align: center;
justify-content: center;
min-height: 503px;
margin: auto;

}

.contact-icon {
    padding: 8px;
gap: 10px;

width: 30px;
height: 30px;
justify-content: center;
text-align: center;
box-shadow: 0px 32px 72px rgba(2, 195, 154, 0.25);
border-radius: 124px;
   

}

.contact-heading {
    width: 306px;
height: 36px;
color: #262C71;

/* Header/3 */

font-family: 'DM Sans';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 150%;
}
  

  .contact-text {
    width: 70%;
    text-align: left;
  }


  .App-contact-details .contact3 {
    align-items: flex-start;
    height: fit-content;
   
  }

.contact3 .socials {
    display: flex;
flex-direction: row;
align-items: flex-start;
padding: 10px 0px;
gap: 24px;
margin-left: 0%;
margin-right: auto;
width: 168px;
height: 24px;



}

.App-contact-details img {
    width: 24px;
    height: 24px;
    margin: auto;
    
}

.App-contact-details {
    display: flex;
flex-direction: column;

gap: 30px;
min-width: 300px;
max-width: 500px;
min-height: 371px;
}

.App-contact-form {
    box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: column;
align-items: left;
padding: 40px 10px;

width: fit-content;
height: fit-content;

/* white/shade 1 */

background: #FFFFFF;
/* accent/hover color */

border: 0.5px solid #F0FAFE;
/* shadow */

box-shadow: 0px 1px 4px rgba(20, 28, 31, 0.02), 0px 8px 12px rgba(20, 28, 31, 0.08);
border-radius: 40px;

}

#mobile {
    display: none;
}



@media  (max-width: 650px) {

  #mobile {
    display: flex;
}

#desktop {
    display: none;
}

}

@media  (min-width: 750px) {

    .contact {
        padding: 5vw 0vw;
        }

    .App-contact-form {
        padding: 40px 70px;
        gap: 32px;
        width: 560px;
        margin: auto;
    }

    .label { 
        min-width: 400px;
    } 

    .input {
        width: 400px;
    }

}


@media  (max-width: 750px) {

    
   

    .label { 
        width: 320px;
    } 

    .input {
        width: 300px;
    }

}


 .label {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-align: left;
    align-items: flex-start;
    padding: 10px;
    gap: 4px;
    min-width: 275px;
    max-width: 410px;
    height: 50px;

}

 .input {
    box-sizing: border-box;

/* Auto layout */


padding: 16px 30px;
gap: 8px;
max-width: 400px;
min-width: 270px;
height: 50px;
text-align: start;
/* Whites/plain white */

background: #FFFFFF;
/* light accent/1 */

border: 1px solid #BCBED7;
border-radius: 8px;

}



.App-contact-form .label-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    min-width: 275px;
    max-width: 500px;
    min-height: 70px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;

}


.App-contact-form .input-text {
    border: 1px solid #DAD8D8;
    border-radius: 8px;
    min-width: 275px;
    max-width: 500px;
   
    height: 121px;
}


.button-submit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 32px;
    gap: 10px;

    width: 145px;
    height: 44px;
    border-radius: 8px;

    color: #F0FAFE;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    min-width: 275px;
    max-width: 552px;
    height: 50px;
    margin: auto;
    margin-top: 10%;
    /* primary/blue */

    background: #262C71;
    box-shadow: 0px 32px 72px rgba(6, 83, 115, 0.08);
    border-radius: 8px;
}


@media  (max-width: 650px) {

    .contact {
    padding: 15vw 0vw;
    }

}


.contact2 {
    display: flex;
flex-direction: row;
align-items: flex-start;
flex-wrap: wrap;
justify-content: space-between;
padding: 0px 5px;
min-height: 100px;
}


.contact1 {
padding: 5px;

}

.contact1 .contact-text {
    height: fit-content;
}


.sentNotification {
    min-height: 70px;
    font-family: 'DM Sans';
    font-style:italic;
    font-weight: 400;
    font-size: 15px;
    line-height: 12px;
    animation: fadeout 3s; 
    
}

input:valid + span::after {
    position: absolute;
    content: "√";
    padding-left: 5px;
    color: #07e207;
}

/* input:invalid + span::after {
    position: absolute;
    content: "X";
    padding-left: 5px;
    color: #e22107;
} */


@keyframes fadeout { 
    0% {  opacity: 1; } 
    50% {  opacity: 0.5; } 
    100% {  opacity: 0; } 
}

.label i {
    position: absolute;
}

.icon {
    padding: 20px 10px;
    width: 40px;
}