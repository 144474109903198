body {
  margin: 0;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
 font-family: 'DM Sans';
}

*{
  font-family: 'DM Sans';
}


